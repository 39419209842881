<template>
  <div class="exercisesTypeTree">
<!--    <a-layout>-->
      <a-layout>
        <a-layout-sider style="background-color: white">
          <a-spin :spinning="spinningMenu">
            <a-menu
                v-model="selectMenu"
            >
              <a-menu-item @click="itemClick(item.id)" v-for="item in menuList" :key="item.id">
              <span >
                {{item.name}}
              </span>
              </a-menu-item>
            </a-menu>
          </a-spin>
        </a-layout-sider>
        <a-layout-content style="margin-left: 10px;background-color: white">
          <a-spin :spinning="spinning">
            <a-button type="primary" style="margin-top: 10px;margin-left: 10px" @click="() => addVisible = true">新增</a-button>
            <a-table
                :rowKey="record=>record.id"
                :pagination="false"
                :columns="columns"
                :data-source="data"
                style="margin-top: 10px;margin-left: 10px"
            >
              <template slot="status" slot-scope="text, row, index">
                <a-switch @change="switchChange($event, 'isOpenExe', row)" checked-children="同步练习" :loading="row.checkedStatus" :checked="row.isOpenExe === 1" un-checked-children="同步练习" />
                <a-switch style="margin: 0 10px" @change="switchChange($event, 'isOpenPast', row)" checked-children="历年真题" :loading="row.checkedStatus" :checked="row.isOpenPast === 1" un-checked-children="历年真题" />
                <a-switch @change="switchChange($event, 'isOpenMock', row)" checked-children="模拟考试" :loading="row.checkedStatus" :checked="row.isOpenMock === 1" un-checked-children="模拟考试" />
              </template>
              <template slot="system" slot-scope="text, row, index">
                <a style="margin-right: 5px" @click="editOpen(row)">编辑</a>
                <a-popconfirm
                    title="是否确认删除?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="del(row.id)"
                >
                  <a style="margin-right: 5px">删除</a>
                </a-popconfirm>
              </template>
            </a-table>
            <div   style="display: flex;justify-content: space-between;margin: 10px">
                    <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{
                        pagination.current
                      }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
              <a-pagination v-model="pagination.current"
                            :total="pagination.total"
                            showQuickJumper
                            showSizeChanger
                            @showSizeChange="pageChange"
                            @change="pageChange" />
            </div>
          </a-spin>
        </a-layout-content>
      </a-layout>
<!--    </a-layout>-->

    <a-modal
        title="新增"
        :visible="addVisible"
        :confirm-loading="addLoading"
        @ok="addOk"
        @cancel="addCanel"
    >
      <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem">
        <a-form-model-item label="职称考试分类" prop="type">
          <a-select placeholder="请选择" v-model="addForm.type" @change="typeChange($event, 'addForm')">
            <a-select-option v-for="item in menuList" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name">
          <a-input placeholder="请输入" v-model="addForm.name" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
        title="编辑"
        :visible="editVisible"
        :confirm-loading="editLoading"
        @ok="editOk"
        @cancel="editCanel"
    >
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem">
        <a-form-model-item label="职称考试分类" prop="type">
          <a-select placeholder="请选择" v-model="editForm.type" @change="typeChange($event, 'editForm')">
            <a-select-option v-for="item in menuList" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name">
          <a-input placeholder="请输入" v-model="editForm.name" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  delExercisesTypeNew,
  getExercisesTypeNew,
  getMoKuaiData,
  postExercisesTypeNew,
  putExercisesTypeEdit
} from "@/service/titleExam_api";

export default {
  name: "exercisesType_tree",
  data () {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      addForm: {},
      editForm: {},
      rules: {
        name:[{ required: true, message: '请输入', trigger: 'blur' }],
        type:[{ required: true, message: '请输入', trigger: 'blur' }],
      },
      editLoading: false,
      addLoading: false,
      addVisible: false,
      editVisible: false,
      spinningMenu: false,
      spinning: false,
      selectMenu: [],
      mode: 'inline',
      theme: 'light',
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '开启状态',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'system' },
        }
      ],
      data: [],
      menuList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
    }//dataReturn
  },
  created() {
    // this.getTableData()
    this.getMoKuaiFunc()
  },
  methods: {
    async pageChange(pageNum,pageSize){
      this.pagination.current = pageNum
      this.pagination.pageSize = pageSize
      await this.getTableData(this.selectMenu[0])
    },
    editOpen (row) {
      this.editVisible = true
      this.editForm = row
    },
    async del (ids) {
      const id = ids
      const res = await delExercisesTypeNew(id)
      if (res.code === 200) {
        this.$message.success('操作成功')
        await this.getTableData(this.selectMenu[0])
      } else {
        this.$message.warning(res.message)
      }
    },
    typeChange (e, type) {
      this[type].type = e
    },

    editOk () {
      this.$refs.editItem.validate(async valid => {
        if (valid) {
          this.editLoading = true
          const user_info = window.localStorage.getItem("user_info");
          const createdByName =  JSON.parse(user_info).user_name
          const data = {
            ...this.editForm,
            createdByName,
          }
          const res = await putExercisesTypeEdit(data)
          if (res.code === 200) {
            this.editLoading = false
            this.editVisible = false
            this.$message.success('操作成功')
            await this.getTableData(this.selectMenu[0])
          }
        } else {
          this.$message.warning('请填写完整表单')
        }
      })
    },
    addOk () {
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          this.addLoading = true
          const user_info = window.localStorage.getItem("user_info");
          const createdByName =  JSON.parse(user_info).user_name
          const data = {
            ...this.addForm,
            createdByName,
          }
          const res = await postExercisesTypeNew(data)
          if (res.code === 200) {
            this.addLoading = false
            this.addVisible = false
            this.$message.success('操作成功')

            await this.getTableData(this.selectMenu[0])
          }
        } else {
          this.$message.warning('请填写完整表单')
        }
      })
    },
    addCanel() {
      this.addVisible = false
    },
    editCanel() {
      this.editVisible = false
    },
    async switchChange (status, type, row) {
      row[type] = Number(status)
      // row.checkedStatus = true
      await this.editTable(row)
    },
    async editTable (row) {
      this.spinning = true
      const data = {
        ...row
      }
      const res = await putExercisesTypeEdit(data)
      if (res.code === 200) {
        this.$message.success('操作成功')
        await this.getTableData(this.selectMenu[0])
      } else {
        this.$message.warning(res.message)
      }
    },
    itemClick (id) {
      this.getTableData(id)
    },
    async getMoKuaiFunc() {
      this.spinningMenu = true
      const data = {
        page: 1,
        pageSize: 10000
      }
      const res = await getMoKuaiData(data)
      if (res.code === 200) {
        this.spinningMenu = false
        this.menuList = res.data
        this.selectMenu = [res.data[0].id]
        await this.getTableData(res.data[0].id)
      }
    },
    async getTableData (typeId) {
      this.spinning = true
      const data = {
        typeId,
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
      }
      const res = await getExercisesTypeNew(data)
      if (res.code === 200) {
        this.spinning = false
        this.pagination.total = res.count
        this.data = res.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.exercisesTypeTree {
  height: 100%;
  margin: -15px -16px;
}
</style>